var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-input-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addWord)}}},[_c('ValidationProvider',{attrs:{"rules":"required|minmax:0,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("Nhập các từ cần điền vào ô bên dưới")]),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-text-field',{attrs:{"label":"Từ mới"},model:{value:(_vm.newWord),callback:function ($$v) {_vm.newWord=$$v},expression:"newWord"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1),_c('v-col',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"black","type":"submit","depressed":"","form":"check-input-form"}},[_vm._v("Thêm từ ")])],1)],1)]}}],null,true)})],1)]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return undefined}}]),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline lighten-2"},[_vm._v(" Bị lặp từ "),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog2 = false}}},[_vm._v(" I accept ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.groupWords,"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.group_type",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.group_type),callback:function ($$v) {_vm.$set(item, "group_type", $$v)},expression:"item.group_type"}})]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return undefined}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-edit-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit( _vm.close )}}},[_c('ValidationProvider',{attrs:{"rules":"required|minmax:0,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Từ"},model:{value:(_vm.editedItem.content),callback:function ($$v) {_vm.$set(_vm.editedItem, "content", $$v)},expression:"editedItem.content"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","type":"submit","depressed":"","form":"check-edit-form"}},[_vm._v(" Ok ")])],1)],1)]}}],null,true)})],1)]}}])})],1)]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[(_vm.message.success != '')?_c('v-alert',{attrs:{"text":"","type":"success"}},[_vm._v(_vm._s(_vm.message.success)+" ")]):_vm._e(),(_vm.message.error != '')?_c('v-alert',{attrs:{"text":"","type":"error"}},[_vm._v(_vm._s(_vm.message.success)+" ")]):_vm._e()],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-btn',{staticClass:"primary",on:{"click":_vm.submitWord}},[_vm._v("Lưu")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }